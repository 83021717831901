import(/* webpackMode: "eager" */ "/codebuild/output/src311477456/src/gojabako.zone/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["AppHost"] */ "/codebuild/output/src311477456/src/gojabako.zone/src/components/AppHost/index.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src311477456/src/gojabako.zone/src/components/Article/style.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["CodeLineHandlers"] */ "/codebuild/output/src311477456/src/gojabako.zone/src/components/CodeLineHandlers/index.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src311477456/src/gojabako.zone/src/components/PageLink/style.module.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src311477456/src/gojabako.zone/src/components/SiteFooter/style.module.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src311477456/src/gojabako.zone/src/components/SiteHeader/style.module.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src311477456/src/gojabako.zone/src/svg/fa-6.6.0/brands/github.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src311477456/src/gojabako.zone/src/svg/fa-6.6.0/brands/x-twitter.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src311477456/src/gojabako.zone/src/svg/rss.svg");
